import { useEffect, useState } from "react";
import { fetchAllHistoryData } from "./databaseHistory";
import { useWeb3React } from "@web3-react/core";

interface historyReturnType {
    date: string
    time: string
    value: string
    chain: string
    result: string
}

export function HistoryTable() {
    const { account} = useWeb3React()

    const [data, setData] = useState<historyReturnType[]>()

    
    async function getUpdatedLeaderboard () {
        const xpData: historyReturnType[] | undefined | null = await fetchAllHistoryData()
        if (xpData){
            setData(xpData);

        }

    }

    useEffect(() => {
        getUpdatedLeaderboard();
    }, [account]);

    return (
        <table className=" w-full">
            <thead className="bg-[#F7F5F6] dark:bg-[#0D111C] w-full">
            <tr className="w-auto h-[80px] bg-[#F3951E]">
                <th className="text-[20px] font-semibold dark:text-white">Date</th>
                <th className="text-[20px] font-semibold dark:text-white">Time</th>
                <th className="text-[20px] font-semibold dark:text-white">Value</th>
                <th className="text-[20px] font-semibold dark:text-white">Chain</th>
                <th className="text-[20px] font-semibold dark:text-white">Result</th>
            </tr>
            </thead>
            <tbody className="w-full justify-between">
            {
                data?.map((val, i) =>
                <tr className="w-full hover:bg-[#FCDEBC] h-[8vh] max-lg:h-[6vh]">
                    <td className="text-[16px] font-medium text-center dark:text-white"># {val.date}</td>
                    <td className="text-[16px] font-medium text-center dark:text-white">{val.time}</td>
                    <td className="text-[16px] font-medium text-center dark:text-white">{val.value}</td>
                    <td className="text-[16px] font-medium text-center dark:text-white">{val.chain}</td>
                    <td className="text-[16px] font-medium text-center dark:text-white">{val.result}</td>
                </tr>
                )
            }
            </tbody>
        </table>
    );
}
