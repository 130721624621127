import React, { useEffect, useState } from "react";
import { fetchXPData } from "../storage/database";
import { useWeb3React } from "@web3-react/core";
import { HiClipboardDocument, HiClipboardDocumentCheck } from "react-icons/hi2";

export function Dashboard() {
  const { account, isActive} = useWeb3React()

  const [currentXp, setCurrentXp] = useState<string>("0")
  const [totalTransactions, setTotalTransactions] = useState<string>("0")
  const [totalAssets, setTotalAssets] = useState<string>("0")
  const [totalInvites, setTotalInvites] = useState<string>("0")
  const [copied, SetCopied] = useState<boolean>(false)

  async function getXp() {
    const userXp = await fetchXPData(account as string)
    if(userXp){
      setCurrentXp(userXp?.totalxp as string)
      setTotalTransactions(userXp?.totaltransactions as string)
      setTotalAssets(userXp?.totalassets as string)
      setTotalInvites(userXp?.totalinvites as string)
    }else{
      setCurrentXp("0")
      setTotalTransactions("0")
      setTotalAssets("0")
      setTotalInvites("0")
    }
    
  }

  function copyButton() {
    navigator.clipboard.writeText(`https://warpdex.co/?ref=${account as string}`);
    SetCopied(true)
  }

  useEffect(() => {
    getXp();
  }, [account]);
  
  return (
    <>
      <div className='flex justify-center items-center w-full h-[100vh] dark:bg-[#0D111C]'>
        <div className="w-[550px] flex flex-col gap-y-2 max-lg:w-full max-lg:px-3">
          {/* 1 */}
          <div className="flex justify-between max-lg:justify-around max-lg:gap-x-3">
            <div className="bg-[#F7F5F6] dark:bg-[#0D111C] py-1 px-2 flex flex-col gap-y-1 w-[180px]">
              <div className="text-[#E8B46F] text-sm ">Total Points</div>
              <div className=" flex justify-center items-center gap-x-3" ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="auto"
                version="1.1"
                viewBox="0 0 291.764 291.764"
                xmlSpace="preserve"
              >
                <path
                  fill="#EFC75E"
                  d="M145.882 0c80.573 0 145.882 65.319 145.882 145.882s-65.31 145.882-145.882 145.882S0 226.446 0 145.882 65.31 0 145.882 0z"
                ></path>
                <path
                  fill="#C93"
                  d="M145.882 27.353c-65.465 0-118.529 53.065-118.529 118.529s53.065 118.529 118.529 118.529 118.529-53.065 118.529-118.529S211.347 27.353 145.882 27.353zm0 218.823c-55.39 0-100.294-44.904-100.294-100.294S90.493 45.588 145.882 45.588s100.294 44.904 100.294 100.294-44.895 100.294-100.294 100.294zm12.127-107.907c-5.452-2.289-25.493-5.452-25.493-14.214 0-6.464 7.495-8.334 11.99-8.334 4.094 0 8.999 1.295 11.589 3.875 1.641 1.577 2.316 2.726 2.854 4.313.684 1.869 1.094 3.875 3.684 3.875h13.357c3.136 0 3.957-.574 3.957-4.021 0-14.789-11.589-23.122-24.809-25.994V86.28c0-2.58-.821-4.167-3.957-4.167h-10.64c-3.136 0-3.957 1.577-3.957 4.167v11.051c-14.178 2.726-26.031 11.634-26.031 27.718 0 18.235 12.683 23.979 26.441 28.566 11.589 3.884 23.724 4.021 23.724 12.063s-5.99 9.765-13.357 9.765c-5.051 0-10.631-1.304-13.366-4.741-1.769-2.152-2.453-4.021-2.58-5.89-.274-3.592-1.769-4.021-4.914-4.021H113.28c-3.136 0-3.957.729-3.957 4.021 0 16.366 13.093 26.286 27.262 29.441v11.206c0 2.58.821 4.167 3.957 4.167h10.64c3.136 0 3.957-1.586 3.957-4.167v-10.905c16.084-2.453 27.125-12.209 27.125-29.441.016-19.522-14.435-23.689-24.255-26.844z"
                ></path>
              </svg><span className="text-[25px] text-[#2A2828] dark:text-white font-bold">{isActive? currentXp : "0"} XP</span></div>
            </div>
            
            <div className="bg-[#F7F5F6] dark:bg-[#0D111C] py-1 px-4 flex flex-col gap-y-1 max-lg:hidden">
              <div className="text-[#E8B46F] text-sm">Total Assets</div>
              <div>{/*img*/}<span className="text-[25px] text-[#2A2828] dark:text-white font-bold">{isActive? totalAssets: "0"}</span></div>
            </div>
          </div>
          {/* 2 */}
          <div className="flex justify-between max-lg:justify-around max-lg:gap-x-3">
            {/* mobile view paste */}
            <div className="bg-[#F7F5F6] dark:bg-[#0D111C] py-1 px-3 flex flex-col gap-y-1 lg:hidden">
              <div className="text-[#E8B46F] text-sm">Total Assets</div>
              <div>{/*img*/}<span className="text-[25px] text-[#2A2828] dark:text-white font-bold">{isActive? totalAssets : "0"}</span></div>
            </div>
            <div className="bg-[#F7F5F6] dark:bg-[#0D111C] py-1 px-2 flex flex-col gap-y-1">
              <div className="text-[#E8B46F] text-sm">Total Trades</div>
              <div><span className="text-[25px] text-[#2A2828] dark:text-white font-bold">{isActive? totalTransactions : "0"}</span></div>
            </div>
            {/* mobile hidden */}
            <div className="bg-[#F7F5F6] dark:bg-[#0D111C] py-1 px-2 flex flex-col gap-y-1 w-[62%] max-lg:hidden">
              <div className="text-[#E8B46F] text-[17px] font-bold">How to earn points</div>
              <div><p className="text-[#7A7778] dark:text-white text-[13px]">You will be rewarded with XP for every successful trade. You will automatically receive XP based on trading volume plus referrals  </p></div>
            </div>
            <div className="bg-[#F7F5F6] dark:bg-[#0D111C] py-1 px-2 flex flex-col gap-y-1">
              <div className="text-[#E8B46F] text-sm">Total Invites</div>
              <div><span className="text-[25px] text-[#2A2828] dark:text-white font-bold">{isActive? totalInvites : "0"}</span></div>
            </div>
          </div>
          {/* mobile 3 */}
          <div className="bg-[#F7F5F6] dark:bg-[#0D111C] py-1 px-2 flex flex-col gap-y-1 w-full lg:hidden text-center">
              <div className="text-[#E8B46F] text-[17px] font-bold">How to earn points</div>
              <div><p className="text-[#7A7778] dark:text-white text-[13px]">You will be rewarded with XP for every successful trade. You will automatically receive XP based on trading volume plus referrals  </p></div>
            </div>
          {/* 3 */}
          <div>
            <div className="px-3 py-2 bg-[#F7F5F6] dark:bg-[#0D111C] text-center">
              {isActive? 
              <span className="flex items-center">
                <h3 className="text-[#7A7778] dark:text-white text-[16px] max-lg:text-[12px]">https://warpdex.co/?ref=${account} </h3> 
                <button
                  onClick={copyButton}>
                    {copied?
                      <HiClipboardDocumentCheck size={40} className="dark:text-[#E8B46F] text-[#7A7778]"/>:
                      <HiClipboardDocument size={40} className="dark:text-[#E8B46F] text-[#7A7778]"/>
                    }
                </button>
              </span>
              :
              <h3 className="text-[#7A7778] dark:text-white text-[16px] max-lg:text-[12px]">Connect your wallet to view your referral link</h3>}
            </div>
          </div>
          {/* 4 */}
          <div className="text-center">
            <h2 className="text-[#E8B46F] text-sm">You both get XP once the user you refer completes a trade</h2>
          </div>
        </div>
      </div>

    </>
  );
}