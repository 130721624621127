import { HistoryTable } from "../storage/HistoryTable";

export function History() {

  return (
    <>
     <div className='flex blur justify-center items-center w-full h-[100vh] dark:bg-[#0D111C] max-lg:px-3'>
        <HistoryTable/>
      </div>
    </>
  );
}