import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useWeb3React } from "@web3-react/core";
import { ConnectionType, getConnection, tryDeactivateConnector } from '../connectors/connections'

type ConnectOptionsParams = {
    connectionType: ConnectionType
}

function DisconnectSidebar({connectionType}: ConnectOptionsParams) {
  let [isOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const { account, isActive, chainId } = useWeb3React()
  const [buttonAddress, setButtonAddress] = useState<string>("Disconnect");


  function loadButtonAddress () {
    if (isActive) {
      setButtonAddress(`${account!?.slice(0, 5)}...${account!?.slice(39, 42)}`)
    }
  };

  useEffect(() => {
    loadButtonAddress();
  }, [account, chainId]);

  async function disconnectWallet() {
    const deactivation = await tryDeactivateConnector(getConnection(connectionType).connector)
    // undefined means the deactivation failed
    if (deactivation === undefined) {
    return
    }
  }

  return (
    <>
      <button
        onClick={openModal}
        className="flex h-10 items-center rounded-full bg-[#F3951E] px-3 py-2.5 tracking-wider text-white transition duration-[150ms] hover:text-uns-blue-12"
      >
        {buttonAddress}
      </button>
      {/* TODO: Fix transition to apply only margin transition */}
      <Transition appear show={isOpen} as={Fragment} >
        <Dialog
          as="div"
          className="fixed w-full h-[100vh] top-2 z-[1030] flex justify-center items-center"
          onClose={closeModal}
          
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-[1040] bg-black bg-opacity-25" />
          </Transition.Child>


          <div
            className={clsx(
                "z-[1041] w-80 overflow-hidden rounded-xl border border-uns-blue-14 bg-[#E8B46F] transition-[margin-right] duration-[250ms]  xl:w-[390px] h-auto",
                isOpen ? "mr-0 xl:mr-0" : "mr-[-320px] xl:mr-[-390px]"
            )}
          >
            <div className="flex h-full w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex w-full flex-shrink flex-grow flex-col flex-nowrap bg-[#F7F5F6] dark:bg-[#0D111C] p-4 py-[100px]">
                  {/* Content section */}
                  <div className="flex justify-center items-center">
                    <div className="gap-[2px] overflow-hidden rounded-xl flex-row items-center justify-center gap-y-7">
                      <h1 className="text-[23px] text-bold block items-center justify-center text-center">
                        DISCONNECT WALLET ?
                      </h1>
                      <div className="flex justify-between mt-3">
                        <button className="flex h-10 items-center rounded-full bg-[#F3951E] px-8 py-2.5 tracking-wider text-white transition duration-[150ms] hover:text-black m-[10px]" onClick={() => disconnectWallet()}>
                            Confirm
                        </button>
                        <button className="flex h-10 items-center rounded-full bg-[#F3951E] px-8 py-2.5 tracking-wider text-white transition duration-[150ms] hover:text-black m-[10px] " onClick={() => closeModal()}>
                            Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default DisconnectSidebar;
