import { useEffect, useState } from "react";

export default function useSystemTheme() {
    const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
      const [isDarkTheme, setIsDarkTheme] = useState(getCurrentTheme());  
      const mqListener = ((e: { matches: boolean | ((prevState: boolean) => boolean); }) => {
          setIsDarkTheme(e.matches);
      });
      
      useEffect(() => {
        const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
        darkThemeMq.addListener(mqListener);
        return () => darkThemeMq.removeListener(mqListener);
      }, []);
      return isDarkTheme;
  }
  