import { Chain, CurrentConfig } from '../helpers/config'

// Chains
const MAINNET_CHAIN_ID = 1
const POLYGON_CHAIN_ID = 137
const BSC_CHAIN_ID = 56
const OPTIMISM_CHAIN_ID = 10
const CELO_CHAIN_ID = 42220
const ARBITRUM_CHAIN_ID = 42161
const BASE_CHAIN_ID = 8453

export const INPUT_CHAIN_ID = CurrentConfig.chain === Chain.BSC ? BSC_CHAIN_ID : POLYGON_CHAIN_ID
export const INPUT_CHAIN_URL =
  CurrentConfig.chain === Chain.BSC ? CurrentConfig.rpc.bsc : CurrentConfig.rpc.mainnet

export const CHAIN_TO_URL_MAP = {
  [POLYGON_CHAIN_ID]: CurrentConfig.rpc.polygon,
  [MAINNET_CHAIN_ID]: CurrentConfig.rpc.mainnet,
  [BSC_CHAIN_ID]: CurrentConfig.rpc.mainnet,
  [OPTIMISM_CHAIN_ID]: CurrentConfig.rpc.mainnet,
  [CELO_CHAIN_ID]: CurrentConfig.rpc.mainnet,
  [ARBITRUM_CHAIN_ID]: CurrentConfig.rpc.mainnet,
  [BASE_CHAIN_ID]: CurrentConfig.rpc.mainnet,
}

type ChainInfo = {
  explorer: string
  label: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: 18
  }
  rpcUrl: string
}

export const CHAIN_INFO: { [key: string]: ChainInfo } = {
  [MAINNET_CHAIN_ID]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrl: 'https://cloudflare-eth.com',
  },
  [POLYGON_CHAIN_ID]: {
    explorer: 'https://polygonscan.com/',
    label: 'Polygon',
    nativeCurrency: { name: 'Polygon Matic', symbol: 'MATIC', decimals: 18 },
    rpcUrl: 'https://polygon-rpc.com',
  },
  [BSC_CHAIN_ID]: {
    explorer: 'https://bscscan.com',
    label: 'Binance Chain',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    rpcUrl: 'https://rpc.ankr.com/bsc',
  },
  [OPTIMISM_CHAIN_ID]: {
    explorer: 'https://optimistic.etherscan.io',
    label: 'Optimism',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrl: 'https://mainnet.optimism.io',
  },
  [CELO_CHAIN_ID]: {
    explorer: 'https://explorer.celo.org/mainnet',
    label: 'Celo',
    nativeCurrency: { name: 'CELO', symbol: 'CELO', decimals: 18 },
    rpcUrl: 'https://forno.celo.org',
  },
  [ARBITRUM_CHAIN_ID]: {
    explorer: 'https://arbiscan.io',
    label: 'Arbitrum',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrl: 'https://arb1.arbitrum.io/rpc',
  },
  [BASE_CHAIN_ID]: {
    explorer: 'https://basescan.org',
    label: 'Base Mainnet',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrl: 'https://mainnet.base.org',
  },

}

// URLs
export const METAMASK_URL = 'https://metamask.io/'