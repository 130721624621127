import { useEffect, useState } from "react";
import { fetchAllXpData } from "./database";
import { useWeb3React } from "@web3-react/core";

interface leaderboardReturnType {
    id: string
    totalxp: string
}

interface leaderboardFillType {
    rank: string
    id: string
    totalxp: string
}

export function LeaderBoardTable() {
    const { account} = useWeb3React()

    const [data, setData] = useState<leaderboardFillType[]>()

    
    async function getUpdatedLeaderboard () {
        const xpData: leaderboardReturnType[] | undefined | null = await fetchAllXpData()
        if (xpData){
            let fullData: leaderboardFillType[] = []
            for (let i = 0; i < xpData.length; i++){
                const shortenAddress = (`${xpData[i].id.slice(0, 5)}...${xpData[i].id.slice(39, 42)}`)
                const singleRow: leaderboardFillType = {
                    rank: String(i+1),
                    id: shortenAddress,
                    totalxp: xpData[i].totalxp
                }
                fullData.push(singleRow);
            }
            setData(fullData);

        }

    }

    useEffect(() => {
        getUpdatedLeaderboard();
    }, [account]);

    return (
        <table className=" w-full">
            <thead className="bg-[#F7F5F6] dark:bg-[#0D111C] w-full">
            <tr className="w-full h-[6vh]">
                <th className="text-[20px] font-semibold dark:text-white">Rank</th>
                <th className="text-[20px] font-semibold dark:text-white">User</th>
                <th className="text-[20px] font-semibold dark:text-white flex gap-x-3 items-center justify-center pt-1 ">Total XP <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="auto"
                version="1.1"
                viewBox="0 0 291.764 291.764"
                xmlSpace="preserve"
                >
                <path
                    fill="#EFC75E"
                    d="M145.882 0c80.573 0 145.882 65.319 145.882 145.882s-65.31 145.882-145.882 145.882S0 226.446 0 145.882 65.31 0 145.882 0z"
                ></path>
                <path
                    fill="#C93"
                    d="M145.882 27.353c-65.465 0-118.529 53.065-118.529 118.529s53.065 118.529 118.529 118.529 118.529-53.065 118.529-118.529S211.347 27.353 145.882 27.353zm0 218.823c-55.39 0-100.294-44.904-100.294-100.294S90.493 45.588 145.882 45.588s100.294 44.904 100.294 100.294-44.895 100.294-100.294 100.294zm12.127-107.907c-5.452-2.289-25.493-5.452-25.493-14.214 0-6.464 7.495-8.334 11.99-8.334 4.094 0 8.999 1.295 11.589 3.875 1.641 1.577 2.316 2.726 2.854 4.313.684 1.869 1.094 3.875 3.684 3.875h13.357c3.136 0 3.957-.574 3.957-4.021 0-14.789-11.589-23.122-24.809-25.994V86.28c0-2.58-.821-4.167-3.957-4.167h-10.64c-3.136 0-3.957 1.577-3.957 4.167v11.051c-14.178 2.726-26.031 11.634-26.031 27.718 0 18.235 12.683 23.979 26.441 28.566 11.589 3.884 23.724 4.021 23.724 12.063s-5.99 9.765-13.357 9.765c-5.051 0-10.631-1.304-13.366-4.741-1.769-2.152-2.453-4.021-2.58-5.89-.274-3.592-1.769-4.021-4.914-4.021H113.28c-3.136 0-3.957.729-3.957 4.021 0 16.366 13.093 26.286 27.262 29.441v11.206c0 2.58.821 4.167 3.957 4.167h10.64c3.136 0 3.957-1.586 3.957-4.167v-10.905c16.084-2.453 27.125-12.209 27.125-29.441.016-19.522-14.435-23.689-24.255-26.844z"
                ></path>
                </svg></th>
            </tr>
            </thead>
            <tbody className="w-full justify-between">
            {
                data?.map((val, i) =>
                <tr className="w-full hover:bg-[#FCDEBC] h-[8vh] max-lg:h-[6vh]">
                    <td className="text-[16px] font-medium text-center dark:text-white"># {val.rank}</td>
                    <td className="text-[16px] font-medium text-center dark:text-white w-[30%]">{val.id}</td>
                    <td className="text-[16px] font-medium text-center dark:text-white w-[40%]">{val.totalxp}</td>
                </tr>
                )
            }
            </tbody>
        </table>
    );
}
