import React from "react";

export function Airdrop() {

  return (
    <>
     <div className='flex blur justify-center items-center w-full h-[100vh] dark:bg-[#0D111C] max-lg:px-3'>
      <div className="flex flex-col items-center gap-y-6 w-[90%]">
        <div className="text-center w-[40%] text-[#7A7778] dark:text-white max-lg:w-full">
          <h2>The Warp airdrop is built for prior users and community members that have actively engaged in Warp ecosystem</h2>
        </div>
        <div>
          <button className="px-5 py-2 w-[22rem] text-white bg-[#F9B15D]">
            Check Eligibility
          </button>
        </div>
      </div>
      </div>
    </>
  );
}