import { createClient } from '@supabase/supabase-js'

export const supabase = createClient("https://xpiqdylzmpfanherincq.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhwaXFkeWx6bXBmYW5oZXJpbmNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTIwNjc4NTAsImV4cCI6MjAyNzY0Mzg1MH0.L346CDQc2NVkvWyUXLxmLtThIKPukfHzyqp3wEx505I")

interface historyReturnType {
  date: string
  time: string
  value: string
  chain: string
  result: string
}

export async function createHistoryTable(account: string, dataInput: historyReturnType) {
  try {
    const { data, error } = await supabase
      .from("transactions")
      .insert({
        id:  account,
        date: dataInput.date,
        time: dataInput.time,
        value: dataInput.value,
        chain: dataInput.chain,
        result: dataInput.result
      })
      .single()
    if (error) throw error;
  } catch (error: any) {
    alert(error.message);
  }
}

export async function fetchHistoryData(account: string) {
    try {
      const { data, error } = await supabase
      .from("transactions")
      .select(`
          date,
          time,
          value,
          chain,
          result
      `)
      .eq('id', account)
      .single();
      return data
    } catch (error: any) {
      alert(error.message);
    }
  }

  export async function fetchAllHistoryData() {
    try {
      const { data, error } = await supabase
        .from("transactions")
        .select(`date,
                time,
                value,
                chain,
                result
        `)
        .order("totalxp", {ascending: false})
        .limit(10);
        return data
      } catch (error: any) {
        alert(error.message);
      }
  }

// export async function updateHistoryData(account: string, volume: number) {
//     const xpData = await fetchHistoryData(account)
//     const newtotalXP = (xpData?.totalxp + 5)
//     const newTotalTransactions = (xpData?.totaltransactions + 1)
//     const newTotalAssets = (xpData?.totalassets + 1)
//     try {
//         const { error } = await supabase
//           .from("transactions")
//           .update({
//             totalxp: newtotalXP,
//             totaltransactions: newTotalTransactions,
//             totalvolume: volume,
//             totalassets: newTotalAssets
//           })
//           .eq("id", account)
//           .single()
//         if (error) throw error;
//       } catch (error: any) {
//         alert(error.message);
//       }
// }


export async function deleteXPData(address: string) {
    const { error } = await supabase
    .from('transactions')
    .delete()
    .eq('id', address)
    return true
}