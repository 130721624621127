import { createClient } from '@supabase/supabase-js'

export const supabase = createClient("https://xpiqdylzmpfanherincq.supabase.co", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhwaXFkeWx6bXBmYW5oZXJpbmNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTIwNjc4NTAsImV4cCI6MjAyNzY0Mzg1MH0.L346CDQc2NVkvWyUXLxmLtThIKPukfHzyqp3wEx505I")


export async function createDataTable(account: string, volume: number) {
  try {
    const { data, error } = await supabase
      .from("transactions")
      .insert({
        id: account,
        totalxp: 5,
        totaltransactions: 1,
        totalvolume: volume,
        totalassets: 1,
        totalinvites: 0
      })
      .single()
    if (error) throw error;
  } catch (error: any) {
    alert(error.message);
  }
}

export async function fetchXPData(account: string) {
    try {
      const { data, error } = await supabase
      .from("transactions")
      .select(`
          id,
          totalxp,
          totaltransactions,
          totalvolume,
          totalassets,
          totalinvites
      `)
      .eq('id', account)
      .single();
      return data
    } catch (error: any) {
      alert(error.message);
    }
  }

export async function fetchAllXpData() {
  try {
    const { data, error } = await supabase
      .from("transactions")
      .select(`id,
              totalxp`)
      .order("totalxp", {ascending: false})
      .limit(10);
      return data
    } catch (error: any) {
      alert(error.message);
    }
}

export async function updateXPData(account: string, volume: number) {
    const xpData = await fetchXPData(account)
    const newtotalXP = (xpData?.totalxp + 5)
    const newTotalTransactions = (xpData?.totaltransactions + 1)
    const newTotalAssets = (xpData?.totalassets + 1)
    try {
        const { error } = await supabase
          .from("transactions")
          .update({
            totalxp: newtotalXP,
            totaltransactions: newTotalTransactions,
            totalvolume: volume,
            totalassets: newTotalAssets
          })
          .eq("id", account)
          .single()
        if (error) throw error;
      } catch (error: any) {
        alert(error.message);
      }
}

export async function updateReferrerData(account: string) {
  const xpData = await fetchXPData(account)
  const newtotalXP = (xpData?.totalxp + 5)
  const newtotalInvites = (xpData?.totalinvites + 1)
  try {
      const { error } = await supabase
        .from("transactions")
        .update({
          totalxp: newtotalXP,
          totalinvites: newtotalInvites,
        })
        .eq("id", account)
        .single()
      if (error) throw error;
    } catch (error: any) {
      alert(error.message);
    }
}

export async function deleteXPData(address: string) {
    const { error } = await supabase
    .from('transactions')
    .delete()
    .eq('id', address)
    return true
}