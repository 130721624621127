// Sets if the example should run locally or on chain
export enum Chain {
  POLYGON,
  MAINNET,
  BSC,
  OPTIMISM,
  CELO,
  ARBITRUM,
  BASE
}

// Inputs that configure this example to run
interface ExampleConfig {
  chain: Chain
  rpc: {
    polygon: string
    mainnet: string
    bsc: string
    optimism: string
    celo: string
    arbitrum: string
    base: string
  }
}

// Example Configuration
export const CurrentConfig: ExampleConfig = {
  chain: Chain.MAINNET,
  rpc: {
    polygon: '',
    mainnet: '',
    bsc: '',
    optimism: '',
    celo: '',
    arbitrum: '',
    base: '',
  },
}