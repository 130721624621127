import { Connection, ConnectionType, onConnectionError } from './connections'

import { initializeConnector } from '@web3-react/core'
import { WalletConnect as WalletConnectV2 } from '@web3-react/walletconnect-v2'



export function buildWalletConnectConnector() {
  const [walletConnectV2, hooks] = initializeConnector<WalletConnectV2>(
    (actions) =>
      new WalletConnectV2({
        actions,
        options: {
          projectId: "3c57d496c068082aff90fde1003d550d",
          chains: [137, 1, 56, 10, 42220, 42161, 8453],
          showQrModal: true,
        },
        onError: onConnectionError
      })
    )
  const walletConnectConnection: Connection = {
    name: "Wallet Connect",
    connector: walletConnectV2,
    hooks: hooks,
    type: ConnectionType.WALLET_CONNECT,
  }
  return walletConnectConnection 
}