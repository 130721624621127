import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Header from './components/Header'
import { Swap } from './layout/swap';
import { Dashboard } from './layout/dashboard';
import { Leaderboard } from './layout/leaderboard';
import { Airdrop } from './layout/airdrop';
// import Footer from './components/Footer';
import { Web3ContextProvider } from './helpers/Web3ContextProvider';
import { Nft } from './layout/nft';
import { History } from './layout/history';


function App() {


  return (
    <Web3ContextProvider>
      <Router>
        <Header />
        <div>
          <Routes>
            <Route path="" element={
              <Swap/>}
            />
            <Route path="/dashboard" element={
              <Dashboard/>}
            />
            <Route path="/airdrop" element={
              <Airdrop/>}
            />
            <Route path="/leaderboard" element={
              <Leaderboard/>}
            />
            <Route path="/nft" element={
              <Nft/>}
            />
            <Route path="/history" element={
              <History/>}
            />
          </Routes>
        </div>
        {/* <Footer /> */}
      </Router>
    </Web3ContextProvider>

  );
}

export default App;