import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import { ConnectionType, switchNetwork } from "../connectors/connections";
import { useWeb3React } from "@web3-react/core";

const NETWORKS = [
  {
    name: "Ethereum",
    imgUrl: "/assets/img/eth.png",
    network: "ethereum",
    chainId: 1
  },
  {
    name: "Polygon",
    imgUrl: "/assets/img/polygon.svg",
    network: "polygon",
    chainId: 137
  },
  {
    name: "Optimism",
    imgUrl: "/assets/img/optimism.svg",
    network: "optimism",
    chainId: 10
  },
  {
    name: "Arbitrum",
    imgUrl: "/assets/img/arbitrum.svg",
    network: "arbitrum",
    chainId: 42161
  },
  {
    name: "Celo",
    imgUrl: "/assets/img/celo.svg",
    network: "celo",
    chainId: 42220
  },
  {
    name: "BNB Chain",
    imgUrl: "/assets/img/bnbchain.svg",
    network: "bnbchain",
    chainId: 56
  },
  {
    name: "Base Network",
    imgUrl: "https://tokenlogo.xyz/assets/chain/base.svg",
    network: "basenetwork",
    chainId: 8453
  },
] as Networks;

type Networks = {
  name: string;
  imgUrl: string;
  network: NetworkDropdownProps["selectedNetwork"];
  chainId: number
}[];

export type selectedNetwork =
  | "ethereum"
  | "polygon"
  | "optimism"
  | "arbitrum"
  | "celo"
  | "bnbchain"
  | "basenetwork";

interface NetworkDropdownProps {
  selectedNetwork: selectedNetwork;
  connectionType: ConnectionType;
}

export default function NetworkDropdown({
  selectedNetwork,
  connectionType
}: NetworkDropdownProps) {

  const { chainId } = useWeb3React()
  
  const [imgUrl, setImgUrl] = useState<string>("");

  function fetchSelectedNetwork(chainId: number , chainImgUrl: string) {
    switchNetwork(chainId, connectionType)
    setImgUrl(chainImgUrl)
  }

  function refreshOnChain() {
    if(chainId){
      const netowrkImgUrl = NETWORKS.find(
        (network) => network.chainId === chainId
      )?.imgUrl;
      setImgUrl(netowrkImgUrl!)
    }else{
      setImgUrl("/assets/img/eth.png")
    }
    
  }

  useEffect(() => {
    refreshOnChain();
  }, [chainId]);

  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className=" flex h-10 w-[60px] items-center justify-center gap-2 rounded-lg text-uns-light-blue transition duration-[250ms] hover:bg-uns-blue-gray">
            <img
              src={imgUrl}
              alt={selectedNetwork}
              className="h-5 w-5 max-w-none"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#98A1C0"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 w-60 origin-top-right justify-between rounded-xl border dark:border-uns-blue-11 bg-[#F7F5F6] dark:bg-uns-blue-10 py-2 shadow-dropdown focus:outline-none sm:bottom-auto sm:top-14">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1 px-2">
                {NETWORKS.map((network) => (
                  <Menu.Item key={network.network}>
                      <button
                        className="grid w-full grid-cols-[min-content_1fr_min-content] bg-[#F7F4F1]  items-center whitespace-nowrap rounded-xl p-2 text-left tracking-wide transition duration-[250ms] dark:hover:bg-uns-blue-gray hover:bg-[#E8B46F]"
                        onClick={() => fetchSelectedNetwork(network.chainId, network.imgUrl)}
                      >
                        <img
                          src={network.imgUrl}
                          alt={network.name}
                          className="mr-3 h-5 w-5 max-w-none"
                        />
                        <div>{network.name}</div>
                      </button>
                  </Menu.Item>
                ))}
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}