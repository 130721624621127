import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { useWeb3React } from "@web3-react/core";
import { ConnectionType, getConnection, tryActivateConnector, tryDeactivateConnector, getHasMetaMaskExtensionInstalled } from '../connectors/connections'
import { METAMASK_URL } from '../connectors/constants'


const WALLETS = [
    {
      name: "MetaMask",
      iconUrl: "/assets/img/metamask.svg",
      new: false,
      type: ConnectionType.INJECTED
  
    },
    {
      name: "WalletConnect",
      iconUrl: "/assets/img/walletConnectIcon.svg",
      new: false,
      type: ConnectionType.WALLET_CONNECT
    },
    {
      name: "Trust Wallet",
      iconUrl: "/assets/img/trust_wallet.svg",
      new: true,
      type: ConnectionType.TRUST_WALLET
    },
    {
      name: "Coinbase Wallet",
      iconUrl: "/assets/img/coinbaseWalletIcon.svg",
      new: true,
      type: ConnectionType.COINBASE_WALLET
    },
  ];
  
type ConnectOptionsParams = {
    onActivate: (connectionType: ConnectionType | undefined ) => void
}


function ConnectOnMount({onActivate}: ConnectOptionsParams) {
  let [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
  }

  const { isActive } = useWeb3React()
  
  async function connectSelectedWallet(connectionType: ConnectionType) {

    const hasMetaMaskExtension = getHasMetaMaskExtensionInstalled()
    if(connectionType === ConnectionType.INJECTED && !hasMetaMaskExtension) {
        <a href={METAMASK_URL}>
        <button>Install Metamask</button>
      </a>
    }
    setIsOpen(false)

    if (isActive) {
        const deactivation = await tryDeactivateConnector(getConnection(connectionType).connector)
        // undefined means the deactivation failed
        if (deactivation === undefined) {
        return
        }
    }
    
    const activation = await tryActivateConnector(getConnection(connectionType).connector)

    if (!activation) {
        return
    }
    onActivate(connectionType)
  }

  return (
    <>
      {/* TODO: Fix transition to apply only margin transition */}
      <Transition appear show={isOpen} as={Fragment} >
        <Dialog
          as="div"
          className="fixed w-full h-[100vh] top-2 z-[1030] flex justify-center items-center"
          onClose={closeModal}
          
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-[1040] bg-black bg-opacity-25" />
          </Transition.Child>


          <div
            className={clsx(
                "z-[1041] w-80 overflow-hidden rounded-xl bg-[#E8B46F] transition-[margin-right] duration-[250ms]  xl:w-[390px] h-auto",
                isOpen ? "mr-0 xl:mr-0" : "mr-[-320px] xl:mr-[-390px]"
            )}
          >
            <div className="flex h-full w-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex w-full flex-shrink flex-grow flex-col flex-nowrap bg-[#F7F5F6] dark:bg-[#0D111C] p-4 py-[100px]">
                  {/* Content section */}
                  <div className="flex justify-center items-center">
                    <div className="gap-[2px] overflow-hidden rounded-xl flex-row items-center justify-center gap-y-7">
                      <h1 className="text-[23px] text-bold block m-[10px]">
                        CONNECT WALLET
                      </h1>
                      {WALLETS.map((wallet) => (
                        <button
                          key={wallet.name}
                          onClick={() => connectSelectedWallet(wallet.type)} 
                          className="flex items-center w-full bg-[#F7F4F1] hover:bg-[#E8B46F] dark:bg-uns-blue p-[18px] dark:hover:bg-uns-blue-15 dark:text-white transition duration-[125ms]"
                        >
                          <img
                            src={wallet.iconUrl}
                            alt={wallet.name}
                            className="h-10 w-10"
                          />
                          <div className="px-2 font-semibold">
                            {wallet.name}
                          </div>
                          {wallet.new && (
                            <div className="rounded-md from-uns-pink-5 from-[4.35%] to-uns-pink-6 to-[96.44%] px-1 py-[2px] text-[8px] font-semibold leading-3 text-uns-blue-16 bg-gradient-[139.57deg]">
                              NEW
                            </div>
                          )}
                        </button>
                      ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ConnectOnMount;
