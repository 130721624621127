import { NavLink, useNavigate } from "react-router-dom";
import type { selectedNetwork } from "./NetworkDropdown";
import NetworkDropdown from "./NetworkDropdown";
import { useContext, useEffect, useState } from "react";
import { NetworkContext } from "../context/NetworkProvider";
import vector from '../Images/warp2.svg'
import vector2 from '../Images/down-arrow-svgrepo-com.svg'
import ConnectSidebar from "./ConnectSidebar";
import { useWeb3React } from "@web3-react/core";
import { ConnectionType } from '../connectors/connections';
import { fetchXPData } from "../storage/database";
import DisconnectSidebar from "./DisconnectModal";
import ConnectOnMount from "./ConnectorOnMount";

const Header = () => {
  const { account, isActive } = useWeb3React()
  const [activeLink, setActiveLInk] = useState(null)
  const [exploreOpen, setExploreOpen] = useState<Boolean>(false)
  const [connectionType, setConnectionType] = useState<ConnectionType>()
  const [currentXp, setCurrentXp] = useState<string>("0")

  const navigate = useNavigate();
  const { network, setNetwork } = useContext(NetworkContext);

  const handleLogoClick = () => {
    navigate("/");
  };

  const onNetworkChange = (network: selectedNetwork) => {
    setNetwork(network);
  };

  // active  links
  const handleLinkClick = (i: any) => {
    setActiveLInk(i)
  }
  const Toggle = (i: any) => {
    setExploreOpen(!exploreOpen)
  }

  async function getXp() {
    const userXp = await fetchXPData(account as string)
    if(userXp){
      setCurrentXp(userXp?.totalxp as string)
    }else{
      setCurrentXp("0")
    }
  }

  useEffect(() => {
    getXp();
  });

  return (
    <div className="fixed top-0 z-[1000] flex w-full flex-row flex-nowrap justify-between">
      <nav className="z-[2] h-[72px] w-full px-3 py-5">
        <div className="flex h-full flex-nowrap align-[initial]">
          {/* left nav items */}
          <div className="flex shrink grow items-center  justify-start align-[initial]">
            <div className="mr-1 cursor-pointer" onClick={handleLogoClick}>
              <img className=" w-[180px] h-auto mt-4 mx-lg:mt-0 max-lg:w-[100px]" src={vector} alt="" />
            </div>
            <div className="flex items-center max-lg:hidden max-lg:mt-2">
              <NavLink
                to="/"
                // className={({
                //   isActive,
                //   isPending,
                // }: {
                //   isActive: boolean;
                //   isPending: boolean;
                // }) =>
                //   clsx(
                //     "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray",
                //     isPending ? "pending" : isActive ? "bg-uns-blue-gray" : "text-[#E8B46F]"
                //   )
                // }
                className={activeLink === 0 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                onClick={() => handleLinkClick(0)}

              >
                Swap
              </NavLink>
              <NavLink
                to="/nft"
                className={activeLink === 1 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                onClick={() => handleLinkClick(1)}
              >
                NFT
              </NavLink>
              <NavLink
                to="/dashboard"
                className={activeLink === 2 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                onClick={() => handleLinkClick(2)}
              >
                Dashboard
              </NavLink>
              <NavLink
                to="/airdrop"
                className={activeLink === 3 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                onClick={() => handleLinkClick(3)}
              >
                Airdrop
              </NavLink>
              <NavLink
                to="/leaderboard"
                className={activeLink === 4 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                onClick={() => handleLinkClick(4)}
              >
                Leaderboard
              </NavLink>
              <NavLink
                to="/history"
                className={activeLink === 5 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                onClick={() => handleLinkClick(5)}
              >
                {/* text-uns-light-blue */}
                History
              </NavLink>
            </div>
            <div className={"lg:hidden flex items-center gap-x-1 cursor-pointer mt-2"} onClick={Toggle}>
              <span className="dark:text-white text-[#7B7B7B]">Explore</span><img src={vector2} alt="" />
              {
                exploreOpen && (
                  <div className="absolute top-20 lg:hidden w-60 origin-top-right justify-between rounded-xl border dark:border-uns-blue-11 bg-[#F7F5F6] dark:bg-uns-blue-10 py-2 shadow-dropdown focus:outline-none sm:bottom-auto sm:top-14">
                    <div className="flex flex-col">
                      <NavLink
                        to="/"
                        // className={({
                        //   isActive,
                        //   isPending,
                        // }: {
                        //   isActive: boolean;
                        //   isPending: boolean;
                        // }) =>
                        //   clsx(
                        //     "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray",
                        //     isPending ? "pending" : isActive ? "bg-uns-blue-gray" : "text-[#E8B46F]"
                        //   )
                        // }
                        className={activeLink === 0 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                        onClick={() => handleLinkClick(0)}

                      >
                        Swap
                      </NavLink>
                      <NavLink
                        to="/nft"
                        className={activeLink === 1 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                        onClick={() => handleLinkClick(1)}
                      >
                        NFT
                      </NavLink>
                      <NavLink
                        to="/dashboard"
                        className={activeLink === 2 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                        onClick={() => handleLinkClick(2)}
                      >
                        Dashboard
                      </NavLink>
                      <NavLink
                        to="/airdrop"
                        className={activeLink === 3 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                        onClick={() => handleLinkClick(3)}
                      >
                        Airdrop
                      </NavLink>
                      <NavLink
                        to="/leaderboard"
                        className={activeLink === 4 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                        onClick={() => handleLinkClick(4)}
                      >
                        Leaderboard
                      </NavLink>
                      <NavLink
                        to="/history"
                        className={activeLink === 5 ? 'my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#E8B46F] bg-uns-blue-gray transition duration-[250ms] hover:bg-uns-blue-gray' : "my-1 rounded-xl px-3.5 py-2 tracking-wide text-[#7B7B7B] transition duration-[250ms] hover:bg-uns-blue-gray"}
                        onClick={() => handleLinkClick(5)}
                      >
                        {/* text-uns-light-blue */}
                        History
                      </NavLink>
                    </div>
                  </div>
                )
              }
            </div>

          </div>
          <div className="hidden h-full shrink grow justify-center self-center">
            <input
              type="text"
              placeholder="Search tokens and NFT collections"
              className="h-full w-full"
            />
          </div>
          <div className="flex gap-x-4 shrink grow justify-end self-center max-lg:mt-2">
            <div className=" flex justify-center items-center gap-x-3 px-3 py-1 bg-[#F7F5F6] rounded-md max-lg:hidden" >
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="auto"
              version="1.1"
              viewBox="0 0 291.764 291.764"
              xmlSpace="preserve"
            >
              <path
                fill="#EFC75E"
                d="M145.882 0c80.573 0 145.882 65.319 145.882 145.882s-65.31 145.882-145.882 145.882S0 226.446 0 145.882 65.31 0 145.882 0z"
              ></path>
              <path
                fill="#C93"
                d="M145.882 27.353c-65.465 0-118.529 53.065-118.529 118.529s53.065 118.529 118.529 118.529 118.529-53.065 118.529-118.529S211.347 27.353 145.882 27.353zm0 218.823c-55.39 0-100.294-44.904-100.294-100.294S90.493 45.588 145.882 45.588s100.294 44.904 100.294 100.294-44.895 100.294-100.294 100.294zm12.127-107.907c-5.452-2.289-25.493-5.452-25.493-14.214 0-6.464 7.495-8.334 11.99-8.334 4.094 0 8.999 1.295 11.589 3.875 1.641 1.577 2.316 2.726 2.854 4.313.684 1.869 1.094 3.875 3.684 3.875h13.357c3.136 0 3.957-.574 3.957-4.021 0-14.789-11.589-23.122-24.809-25.994V86.28c0-2.58-.821-4.167-3.957-4.167h-10.64c-3.136 0-3.957 1.577-3.957 4.167v11.051c-14.178 2.726-26.031 11.634-26.031 27.718 0 18.235 12.683 23.979 26.441 28.566 11.589 3.884 23.724 4.021 23.724 12.063s-5.99 9.765-13.357 9.765c-5.051 0-10.631-1.304-13.366-4.741-1.769-2.152-2.453-4.021-2.58-5.89-.274-3.592-1.769-4.021-4.914-4.021H113.28c-3.136 0-3.957.729-3.957 4.021 0 16.366 13.093 26.286 27.262 29.441v11.206c0 2.58.821 4.167 3.957 4.167h10.64c3.136 0 3.957-1.586 3.957-4.167v-10.905c16.084-2.453 27.125-12.209 27.125-29.441.016-19.522-14.435-23.689-24.255-26.844z"
              ></path>
            </svg><span className="text-[21px] text-[#2A2828] max-lg:text-[11px] font-semibold ">{isActive? currentXp : "0"} XP</span>
            </div>
            <div className="flex items-center gap-3">
              <NetworkDropdown
                selectedNetwork={network}
                connectionType={connectionType!}
              />
              {isActive ?
                <DisconnectSidebar
                 connectionType={connectionType!}/>
                :
                <>
                  <ConnectOnMount
                  onActivate={setConnectionType}/>
                  <ConnectSidebar
                  onActivate={setConnectionType} />
                </>
              }
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
export default Header;