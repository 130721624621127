import { SwapWidget, Theme } from '@uniswap/widgets'
import { Widget } from "@kyberswap/widgets";
import '@uniswap/widgets/fonts.css'
import useSystemTheme from '../helpers/detectTheme';
import { useActiveProvider } from '../connectors/connections';
import { createDataTable, fetchXPData, updateReferrerData, updateXPData } from '../storage/database';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { TokenList } from '../helpers/tokenList';


export function Swap() {
  const { account, isActive, chainId } = useWeb3React()
  const provider = useActiveProvider()

  const [referral, setReferral] = useState<string>("");
  const [kyberBsc, setKyberBsc] = useState<boolean>(false);
  const location = useLocation();

  function checkChain() {
    if(chainId === 56 || chainId === 8453){
      setKyberBsc(true)
    }else{
      setKyberBsc(false)
    }
  }

  useEffect(() => {
    checkChain()
 }, [chainId]);

  useEffect(() => {
     const params = new URLSearchParams(location.search);
     const referral = params.get('ref');
     if (referral) {
        setReferral(referral)
     }
  }, [location.search, account]);

  const theme = useSystemTheme()

  const darkTheme: Theme = {
    primary: '#fff',
    secondary: '#fff',
    interactive: '#E8B46F',
    onInteractive: '#fff',
    container: '#0D111C',
    module: '#0D111C',
    accent: '#E8B46F',
    outline: '#808080',
    dialog: '#FFF',
    // borderRadius: 0.8,
  }

  const lightTheme: Theme = {
    primary: '#000',
    secondary: 'gray',
    interactive: '#E8B46F',
    container: '#fff',
    module: '#F7F5F6',
    accent: '#E8B46F',
    outline: '#000',
    dialog: '#FFF',
    tokenColorExtraction: true,
    // borderRadius: 0.8,
  }


  const defaultDarkTheme = {
    text: "#fff",
    subText: 'fff',
    primary: '#0D111C',
    dialog: '#000',
    secondary: '#0D111C',
    interactive: '#E8B46F',
    stroke: '#808080',
    accent: '#E8B46F',
    success: "green",
    warning: "yellow",
    error: "red",
    fontFamily: "system-ui",
    borderRadius: "30px", 
    buttonRadius: "15px",
    boxShadow: ""
};

const defaultLightTheme = {
  text: "#000",
  subText: '#000',
  primary: '#fff',
  dialog: '#FFF',
  secondary: '#F7F5F6',
  interactive: '#E8B46F',
  stroke: '#E8B46F',
  accent: '#E8B46F',
  success: "green",
  warning: "yellow",
  error: "red",
  fontFamily: "Helvetica", 
  borderRadius: "30px", 
  buttonRadius: "15px",
  boxShadow: ""
};

async function createAndUpdateXp() {
  const xpData = await fetchXPData(account as string)
  if(xpData?.totaltransactions > 0){
    updateXPData(account as string, 2)
  }else{
    createDataTable(account as string, 2);
  }   
  if(referral){
    updateReferrerData(referral);
  }
}

  return (
    <div className='flex justify-center items-center w-full h-[100vh] dark:bg-[#0D111C]'>
      <div className=' textstyle max-sm:top-[7%] mt-[10brem]'>
        <h3 className='text-white text-[30px] text-center h-full font-bold '/>
          <div className="Uniswap border-[#E8B46F] border p-[15px] rounded-xl">
            {kyberBsc?
              <>
                <Widget
                    client="wasp"
                    theme={theme ? defaultDarkTheme : defaultLightTheme}
                    width={370}
                    enableRoute = {true}
                    enableDexes="kyberswap-elastic,uniswapv3,uniswap"
                    provider={provider}
                    onTxSubmit={() => createAndUpdateXp()}
                    showDetail={false}
                    feeSetting={{
                      feeAmount: 100,
                      feeReceiver: "0xd3e190Ba894D0c4981ffc1e781b80E6b8dA041E5",
                      chargeFeeBy: "currency_in",
                      isInBps: true
                    }}
                />
                <div className=' h-[30px] w-[370px] dark:bg-[#0D111C] bg-[#fff] absolute mt-[-35px]'/>
              </>
            : 
              <SwapWidget
                provider={provider}
                convenienceFee={100}
                convenienceFeeRecipient={"0xd3e190Ba894D0c4981ffc1e781b80E6b8dA041E5"}
                theme={theme ? darkTheme : lightTheme}
                disableBranding={true}
                hideConnectionUI={isActive? false : true}
                onTxSuccess={() => createAndUpdateXp()}
                tokenList={TokenList}
              />
            }
          </div>
      </div>
    </div>
  );
}