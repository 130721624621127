import { initializeConnector } from '@web3-react/core';
import { Connection, ConnectionType, onConnectionError } from './connections'
import { TrustWallet } from "@trustwallet/web3-react-trust-wallet";

export function buildTrustWalletConnector() {

  const [trustWallet, hooks] = initializeConnector<TrustWallet>(
    (actions) => new TrustWallet({ 
        actions})
  );

  const trustWalletConnection: Connection = {
    name: "Trust Wallet",
    connector: trustWallet,
    hooks: hooks,
    type: ConnectionType.TRUST_WALLET,
  }
  return trustWalletConnection 
}